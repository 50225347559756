import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MarkdownEditorComponent, MdEditorOption } from 'ngx-markdown-editor';
import { HolAttachments, HolisFile } from '../../models/hol-attachments.model';
import { HolNoteTemplate } from '../../models/hol-note-template';
import * as moment from 'moment/moment';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { FilesService } from '../../services/files.service';
import { NoteTemplateService } from '../../services/note-template.service';
import { UserService } from '../../services/user.service';
import { HttpClient } from '@angular/common/http';
import { MarkdownService } from '../markdown-editor/markdown.service';
import { clone } from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';

@Component({
  selector: 'app-note-markdown',
  templateUrl: './note-markdown.component.html',
  styleUrls: ['./note-markdown.component.scss'],
})
export class NoteMarkdownComponent implements OnInit, AfterViewInit {
  @Input() context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlScenario?: string;
    htmlDate: string;
    htmlNextInfo?;
    htmlTemplate: string;
    task?: any;
    initLabelName?: string;
  };
  @Input() attachment: string;
  @Input() noteFile: HolisFile;
  @Input() public attachments: HolAttachments;
  @Input() public readOnly: boolean = false;
  @Output() isNoteSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  public options: MdEditorOption = {
    resizable: false,
    showBorder: false,
    hideIcons: ['FullScreen', 'Image'],
    showPreviewPanel: false,
    markedjsOpt: {
      sanitize: true,
    },
  };
  public title: string;
  isUTC: boolean;
  localHourOnly: boolean;
  public htmlTemplate: string;
  public loading = false;
  public noteTemplates: HolNoteTemplate[] = [];
  public displayName = '';
  public today = moment();
  public todayUTC = moment.utc();
  public editionMode: boolean = false;
  public showReadMore: boolean = false;
  @ViewChild('mdEditor', { static: false }) mdEditor: MarkdownEditorComponent;
  public attachmentBase: string;
  @Input() required: boolean = false;
  @ViewChild('markdownPreview', { static: false }) composantMarkdown;

  constructor(
    private moduleConfig: ModuleConfigService,
    private readonly filesService: FilesService,
    private noteTemplateService: NoteTemplateService,
    private readonly userService: UserService,
    private http: HttpClient,
    public markdownService: MarkdownService,
    private readonly matDialog: MatDialog,
    private translate: TranslatePipe,
  ) {
    // this.doUpload = this.doUpload.bind(this);
  }

  ngAfterViewInit(): void {
    try {
      const element = this.composantMarkdown.element.nativeElement;
      const liens = element.querySelectorAll('a');
      liens.forEach((lien: HTMLElement) => {
        lien.addEventListener('click', event => {
          event.preventDefault();
          return false;
        });
      });
    } catch (e) {}
  }

  ngOnInit() {
    this.loading = true;
    let dateFormat = '';
    dateFormat = dateFormat + 'DD/MM/YY[\r\n]';
    dateFormat += 'HH:mm';
    this.isUTC = !this.moduleConfig.config.localHours;
    if (this.isUTC) {
      dateFormat += '[Z]';
      if (this.context && this.context.htmlDate) {
        this.context.htmlDate = moment.utc(this.context.htmlDate).format(dateFormat);
      } else {
        this.context.htmlDate = this.todayUTC.format(dateFormat);
      }
      if (this.context.htmlNextInfo && this.context.htmlNextInfo.createdAt) {
        this.context.htmlNextInfo.createdAt = moment.utc(this.context.htmlNextInfo.createdAt).format(dateFormat);
      } else if (this.context.htmlNextInfo && !this.context.htmlNextInfo.createdAt) {
        this.context.htmlNextInfo.createdAt = this.todayUTC.format(dateFormat);
      }
      if (this.context.htmlNextInfo && this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = moment.utc(this.context.htmlNextInfo.nextInfoTime).format(dateFormat);
      } else if (this.context.htmlNextInfo && !this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = this.todayUTC.format(dateFormat);
      }
    } else {
      if (!this.context.htmlDate) {
        this.context.htmlDate = moment(this.context.htmlDate).format(dateFormat);
      } else {
        this.context.htmlDate = this.today.format(dateFormat);
      }
      if (this.context.htmlNextInfo && !this.context.htmlNextInfo.createdAt) {
        this.context.htmlNextInfo.createdAt = moment(this.context.htmlNextInfo.createdAt).format(dateFormat);
      }
      if (this.context.htmlNextInfo && this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = moment(this.context.htmlNextInfo.nextInfoTime).format(dateFormat);
      } else if (this.context.htmlNextInfo && !this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = this.today.format(dateFormat);
      }
    }

    this.attachmentBase = clone(this.attachment);

    this.displayName = this.userService.getCurrentUserObject().fullName;

    this.noteFile =
      this.noteFile && this.noteFile != undefined
        ? this.noteFile
        : {
            fileName: '',
            url: '',
          };

    this.noteTemplateService.getAllNoteTemplate().then(data => (this.noteTemplates = data));
    this.loading = false;
    this.isNoteSave.emit(true);
  }

  public changeTemplate(event: MatSelectChange): void {
    this.loading = true;
    this.http.get(event.value, { responseType: 'text' }).subscribe(data => {
      this.loading = false;
      this.attachment = data;
    });
    this.isNoteSave.emit(false);
  }

  public removeMarkdownLinks(markdownText: string): string {
    // Regex pour capturer les liens inline [text](url)
    const inlineLinkRegex: RegExp = /\[([^\]]+)\]\(([^)]+)\)/g;

    // Regex pour capturer les liens de référence [text][ref]
    const referenceLinkRegex: RegExp = /\[([^\]]+)\]\[([^\]]+)\]/g;

    // Supprimer les liens inline
    markdownText = markdownText.replace(inlineLinkRegex, '');

    // Supprimer les liens de référence
    markdownText = markdownText.replace(referenceLinkRegex, '');

    return markdownText;
  }

  public editionModeToggle(): void {
    this.editionMode = !this.editionMode;

    if (this.editionMode) {
      //need to focus on mdEditor after the editionMode is set to true
      setTimeout(() => this.mdEditor.aceEditorContainer.nativeElement.getElementsByTagName('textarea')[0].focus(), 500);
    }
  }

  public toggleShowMore(): void {
    this.showReadMore = !this.showReadMore;
  }

  public open() {
    window.open(this.attachments.noteFile.url, '_blank');
  }

  public validateNote(): void {
    const nameFile = `note-${this.context.module.toLocaleLowerCase()}-${this.context.category
      .substring(0, 3)
      .toLocaleLowerCase()
      .replace(/[éèê]/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
    this.loading = true;
    let base64pdf: string | ArrayBuffer;

    const htmlSource: Element = document.getElementsByClassName('preview-panel')[0];

    // TODO: Don't use this jQuery selector!
    const $htmlContent = $(htmlSource.outerHTML);
    // Replace new lines by <br/> and spaces by &nbsp in code nodes

    // replace code and pre nodes by div (fromHTML ignores code and pre nodes)
    // $htmlContent.html(

    this.htmlTemplate = this.markdownService.createHtmlContent($htmlContent[0].outerHTML, this.context);

    setTimeout(() => {
      const blob = new Blob([this.htmlTemplate], { type: 'text/html' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        base64pdf = reader.result.toString();
        this.sendHtmlToDatabase(base64pdf, nameFile).then(() => {
          this.attachments.note = this.mdEditor.markdownValue;
          this.attachments.noteFile = this.noteFile;
          this.attachmentBase = clone(this.attachments.note);
          this.editionModeToggle();
          this.loading = false;
          this.isNoteSave.emit(true);
        });
      };
    }, 10);
  }

  public async sendHtmlToDatabase(data: string, nameFile: string): Promise<any> {
    return this.filesService.uploadFile(nameFile, { base64: data }).then(
      url => {
        this.noteFile.url = url;
        this.noteFile.fileName = nameFile;
        this.loading = false;
      },
      err => {
        console.error(err);
        this.loading = false;
      },
    );
  }

  public cancelEditor(): void {
    this.attachment = this.attachmentBase;
    this.editionMode = false;
    this.isNoteSave.emit(true);
  }

  public deleteNote() {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.matDialog.open(ConfirmationModalComponent, {
      data: {
        modalTitle: this.translate.transform('COMMON.MODALS.CONFIRMATION.NOTE_TITLE'),
        modalContent: this.translate.transform('COMMON.MODALS.CONFIRMATION.NOTE_CONTENT'),
        modalQuestion: this.translate.transform('COMMON.MODALS.CONFIRMATION.QUESTION'),
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          this.attachments.note = null;
          this.attachments.noteFile = null;
          this.attachment = '';
          this.isNoteSave.emit(true);
        }
      },
    });
  }

  onModelChange($event: any) {
    if (this.attachment != this.attachmentBase) {
      this.isNoteSave.emit(false);
    }
  }
}
